<template>
  <div class="">
    <van-nav-bar left-arrow title='详情' fixed @click-left='$router.go(-1)' />
    <van-collapse v-model="activeNames" accordion style='margin-top: 55px'>
      <div class="splitTitle">{{ $t('inv.基本信息') }}</div>
      <van-collapse-item :title="$t('inv.基本信息')" name="1">
        <div class="items">
          <h5>{{ $t('inv.调拨单号') }}</h5>
          <p>{{ this.ruleForm.transferOrderNo }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('inv.业务员') }}</h5>
          <p>{{ this.ruleForm.businessPeopleName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('inv.审批状态') }}</h5>
          <p>{{ this.ruleForm.orderStatus | setDict('orderStatus')}}</p>
        </div>
        <div class="items">
          <h5>{{ $t('inv.调拨日期') }}</h5>
          <p>{{ this.ruleForm.transferOrderDate }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('inv.调出仓库') }}</h5>
          <p>{{ this.ruleForm.transferOutWarehouseName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('inv.调入仓库') }}</h5>
          <p>{{ this.ruleForm.transferIntoWarehouseName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('inv.备注') }}</h5>
          <p>{{ this.ruleForm.remarks }}</p>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('inv.商品信息')" name="3">
        <div v-for="(item, index) in ruleForm.lineList" :key="index" class="detailLine">
          <div class="items">
            <h5>{{ $t('inv.商品编码') }}:</h5>
            <p>{{ item.materialCode}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('inv.商品名称') }}:</h5>
            <p>{{ item.commodityDetail}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('inv.商品类别') }}:</h5>
            <p>{{ item.commodityCategory}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('inv.工厂产品型号') }}:</h5>
            <p>{{ item.model}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('inv.增值税率') }}:</h5>
            <p>{{ item.vatRates}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('inv.商品属性集') }}:</h5>
            <p>{{ item.materialDesc}}</p>
          </div>
          <div class="items">
            <h5>{{ $t('inv.转换率') }}:</h5>
            <p>{{ item.conversionRatio }}%</p>
          </div>
          <div class="items">
            <h5>{{ $t('inv.批次号') }}:</h5>
            <p>{{ item.lotNumber }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('inv.可用库存') }}:</h5>
            <p>{{ item.availableStock }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('inv.第一单位') }}-数量:</h5>
            <p>{{ item.allocationQuantity | formatAmount(6,false) }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('inv.第一单位') }}-单位:</h5>
            <p>{{ item.unit }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('inv.第二单位') }}-数量:</h5>
            <p>{{ item.importWarehousUnitTwoNumber | formatAmount(6,false) }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('inv.第二单位') }}-单位:</h5>
            <p>{{ item.importWarehousUnitTwo }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('inv.调出单价') }}:</h5>
            <p>{{ item.allocationCost | formatAmount(6,false) }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('inv.调出金额') }}:</h5>
            <p>{{ item.allocationAmount | formatAmount }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('inv.备注') }}:</h5>
            <p>{{ item.remarks}}</p>
          </div>
        </div>
      </van-collapse-item>

      <div class="splitTitle">{{ $t('inv.附件') }}</div>
      <van-collapse-item :title="$t('inv.附件信息')" name="7">
        <div v-if="!attachmentList || !attachmentList.length">{{ $t('inv.无') }}</div>
        <div v-for="(item, index) in this.attachmentList" :key="index" class="detailLine">
          <div class="items">
            <h5>{{ $t('inv.序号') }}</h5>
            <p>{{ index + 1 }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('inv.附件名称') }}</h5>
            <p>{{ item.attachmentName }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('inv.操作') }}</h5>
            <p>
              <van-icon name="eye-o" color="#19d2fe" size="20" @click="handleFileView(item.id,item.attachmentName)" />
              <!--              <a href="#" @click="handleFileView(item.id,item.attachmentName)">{{ $t('pur.预览') }}</a>-->
              <!--              <a href="#" @click="downloadAttachment(item.id,item.attachmentName)" style="margin-left: 7px">{{ $t('pur.下载') }}</a>-->
            </p>
          </div>
          <div class="items">
            <h5>{{ $t('inv.上传人') }}</h5>
            <p>{{ item.createName }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('inv.上传时间') }}</h5>
            <p>{{ item.createTime }}</p>
          </div>
          <van-divider dashed :style="{ borderColor: '#333' }"/>
        </div>
      </van-collapse-item>

      <div class="splitTitle">{{ $t('inv.系统信息') }}</div>
      <van-collapse-item :title="$t('inv.系统信息')" name="4">
        <div class="items">
          <h5>{{ $t('inv.制单日期') }}</h5>
          <p>{{ this.ruleForm.createTime }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('inv.制单人') }}</h5>
          <p>{{ this.ruleForm.createByName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('inv.修改时间') }}</h5>
          <p>{{ this.ruleForm.lastmodifiedTime }}</p>
        </div>
      </van-collapse-item>
    </van-collapse>
    <div style="margin: 8px 0;">
      <my-history-panel ref="historyPanel" :pid="ruleForm.processInstanceId"></my-history-panel>
    </div>
    <div v-if="approvalShow">
      <my-approval
        v-if="ruleForm"
        class="approvalFixed"
        :woCodeValue="woCode"
        :businessInfo="ruleForm"
        :detailPageName="$route.name"
        :customSubmitBtnName="customSubmitBtnName"
        :processName="processInstanceName"
        :taskId="taskInstanceId"
        @processCallback="processCallback"
        @checkBeforeProcess="checkBeforeProcess"
        @beforeSubmitCallBack="beforeSubmitCallBack"
        @closeStartCallback="closeStartCallback"
      >
      </my-approval>
    </div>
  </div>
</template>

<script>
  import { Dialog } from 'vant';
  import util from '../../../libs/util';
  import myHistoryPanel from '@/views/business/components/myHistoryPanel';
  import myApproval from '@/views/business/components/myApproval';

  export default {
    name: 'visitorFieldComponent',
    components: {
      myHistoryPanel,
      myApproval
    },
    data() {
      return {
        attachmentList: [],
        loading: false,
        finished: false,
        activeNames: '1',
        hostName: '',
        task: '',
        personId: '',
        approvalShow: false,
        customSubmitBtnName: ['同意', '不同意'],
        id: '',
        key: '',
        title: '',
        detail: null,
        active: 0,
        businessKey: '',
        mappingId: '',
        taskInstanceId: '',
        currentNode: '',
        variables: {},
        taskType: '',
          ruleForm: {
              id: '',
              transferOrderNo: '',
              salesman: '',
              salesmanId: '',
              orderStatus: '',
              transferOrderDate: '',
              transferOrderNature: '',
              deliveryType: '',
              transferOutWarehouseId: '',
              transferOutWarehouse: '',
              transferOutWarehouseName: '',
              transferIntoWarehouseId: '',
              transferIntoWarehouse: '',
              transferIntoWarehouseName: '',
              logisticsCompany: '',
              logisticsNumber: '',
              logisticsCountries: '',
              logisticsProvinces: '',
              logisticsCity: '',
              logisticsAddress: '',
              receiverId: '',
              receiver: '',
              receiveTel: '',
              idNumber: '',
              area: '',
              areaName: '',
              remarks: '',
              createBy: '',
              createTime: '',
              processInstanceId: '',
              lastmodifiedTime: '',
              createByName: '',
              productManager: '',
              lineList: [],
              logisticsDetailList: []
          },
          woCode: 'invAllocatingOutbound',
          processInstanceName: '调拨出库'
      };
    },
    props: {},
    methods: {
      /**
       * 根据文件id和文件名生成预览链接
       * @param id 附件id
       * @param fileName 附件名
       * @returns {string}
       */
      handleFileView (id, fileName) {
        this.$FilePrvwUtils.openFile(id,fileName);
      },
      downloadAttachment(id, fileName) {
        this.$myHttp({
          method: "post",
          url: "/microarch/sys/sysAttchement/download",
          data: {
            "entity": {
              "id": id
            }
          },
          responseType: "arraybuffer"
        }).then(res => {
          // 成功回调
          var headers = res.headers;
          var blob = new Blob([res.data], {
            type: headers["content-type"]
          });
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
        });
      },
      getAttachmentList() {
        this.$myHttp({
          method: "post",
          url: "/microarch/sys/sysAttchement/list",
          data: {
            "entity": {
              "bizId": this.ruleForm.id
            }
          }
        }).then(res => {
          let backData = res.data;
          if (backData) {
            this.attachmentList = backData;
          }
        });
      },
      getProcessMappingId() {
        var userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.$myHttp({
          method: 'post',
          url: '/microarch/activiti/mapping/findBywoCodeAndOrgDept',
          data: {
            entity: {
              woCode: this.woCode,
              orgId: userInfo.companyId,
              deptId: userInfo.deptId
            }
          }
        }).then(res => {
          if (res.data.ext.code === '2000') {
            this.mappingId = res.data.ext.mappingId;
          } else {
            Toast.fail({
              message: res.data.ext.message,
              type: 'error',
              duration: 1500
            });
          }
        });
      },
      backfilleditData(id) {
        this.$myHttp({
          method: 'POST',
          url: '/microarch/inventory/invTransferOrderHeader/view',
          data: {
            entity: {
              id: id
            }
          }
        }).then(res => {
          // 成功回调方法
          var data = res.data.entity;
          this.ruleForm = data;
          this.variables.activitiData = res.data.entity;
          this.getProcessMappingId();
          this.getAttachmentList();
          this.$refs.historyPanel.getData(this.ruleForm.processInstanceId);
          if (this.$route.query.type) {
            this.approvalShow = true;
          }
        });
      },
      setApprovalData(row) {
        if (row && row.pid) {
          this.ruleForm.id = row.businessKey;
          this.taskInstanceId = row.id;
          this.taskType = row.taskType;
        }
      },
      /**
       * 业务提交之前校验
       * */
      checkBeforeProcess(type) {
        if (type) {
          if (type === 'beforeAddsign') {
            this.$refs.demoTemperatureEdit.beforeAddsign();
          } else if (type === 'afterAddsign') {
            this.$refs.demoTemperatureEdit.afterAddsign();
          } else if (type === 'endProcess') {
            this.$refs.demoTemperatureEdit.endProcess();
          } else if (type === 'repelProcess') {
            this.repelProcess();
          } else {
            if (!this.taskInstanceId) {
              var params = {
                businessKey: this.ruleForm.id,
                mappingId: this.mappingId,
                processInstanceName: this.processInstanceName,
                userName: sessionStorage.getItem('userName'),
                variables: this.variables
              };
              this.startProcessAction(params);
            } else {
              this.$refs.demoTemperatureEdit.submit();
            }
          }
        } else {
          Toast.fail({
            message: this.$t('inv.数据校验失败'),
            type: 'warning'
          });
        }
      },
      // 业务发起流程
      startProcessAction(params) {
        if (params.mappingId) {
          params.mappingId = this.mappingId;
        }
        this.processInstanceName = util.getWorkflowCode(this.ruleForm.transferOrderNo, this.processInstanceName);
        this.$myHttp({
          method: 'post',
          url: '/microarch/inventory/invTransferOrderHeader/start',
          data: params
        })
          .then(res => {
            var backData = res.data.ext;
            if (backData) {
              this.$refs.demoTemperatureEdit.submitLoading = false;
              this.$refs.demoTemperatureEdit.isStart = true;
              this.$refs.demoTemperatureEdit.form.currentNodeName = backData.taskName;
              this.$refs.demoTemperatureEdit.firstTaskName = backData.taskName;
              this.$refs.demoTemperatureEdit.isFirstNode = true;
              this.$refs.demoTemperatureEdit.taskId = backData.taskId;
              this.$refs.demoTemperatureEdit.processInstanceId = backData.processInstanceId;
              this.$refs.demoTemperatureEdit.transactProcess(backData.taskId, backData.processInstanceId);
            } else {
              Toast.fail({
                message: this.$t('inv.流程发起异常'),
                type: 'warning'
              });
              this.$refs.demoTemperatureEdit.submitLoading = false;
            }
          })
          .catch(_ => {
            this.$refs.demoTemperatureEdit.submitLoading = false;
          });
      },
      // 业务提交流程
      submitProcessAction(params) {
        this.processInstanceName = util.getWorkflowCode(this.ruleForm.transferOrderNo, this.processInstanceName);
        this.$myHttp({
          method: 'post',
          url: '/microarch/inventory/invTransferOrderHeader/submit',
          data: params
        })
          .then(res => {
            if (res.data.ext.success) {
              this.processCallback(null, null, this.taskType);
            } else {
              Toast.fail({
                message: res.data.ext.msg,
                type: 'warning'
              });
            }
          })
          .catch(_ => {});
      },
      /***
       * 业务撤销流程
       */
      repelProcess() {
        var _this = this;
        _this
          .$confirm(this.$t('myApprovalBtn.confirmRevocationProcess'), {
            cancelButtonClass: 'sysBackBtn'
          })
          .then(_ => {
            this.$myHttp({
              method: 'post',
              url: '/microarch/inventory/invTransferOrderHeader/undoProcess',
              data: {
                entity: {
                  id: this.ruleForm.id,
                  processInstanceId: this.ruleForm.processInstanceId
                }
              }
            })
              .then(res => {
                if (res.data.success === undefined || res.data.success) {
                  Toast.fail({
                    message: this.$t('inv.撤销成功'),
                    type: 'success'
                  });
                  this.processCallback(null, null, this.taskType);
                } else {
                  Toast.fail({
                    message: res.data.msg,
                    type: 'warning'
                  });
                }
              })
              .catch(_ => {});
          })
          .catch(_ => {});
      },

      /***
       * 关闭发起流程审批窗口
       * 删除流程实例，回滚业务状态
       */
      closeStartCallback(pid) {
        this.$myHttp({
          method: 'POST',
          url: '/microarch/inventory/invTransferOrderHeader/deleteProcess',
          data: {
            entity: {
              id: this.ruleForm.id,
              processInstanceId: pid
            }
          }
        })
          .then(res => {
            this.$refs.demoTemperatureEdit.dialogVisible = false;
            this.$refs.demoTemperatureEdit.taskId = '';
            this.$refs.demoTemperatureEdit.processInstanceId = '';
            this.processCallback(null, null, this.taskType);
          })
          .catch(_ => {});
      },

      /**
       * 提交之前校验
       * */
      beforeSubmitCallBack(params) {
        // 业务参数校验 此时可以从params中获取提交审批所有的参数
        // 根据这些参数做相应的判断 如果通过调用流程组件中提交方法
        // 不通过给出相应的提示或者触发其他业务操作
        // let selectUserArray = params.candidateUserIds[params.nextId]
        // console.log(selectUserArray);
        this.submitProcessAction(params);
      },
      /**
       * 流程审批之后业务数据更改
       * */
      processCallback(piid, optionType, taskType) {
        var _this = this;
        // util.closeCurrentTag('sale/return/saleReturnApplyEdit', this);
        // // 成功回调方法
        // if (taskType === 'transated') {
        //   _this.$router.push({
        //     name: 'sys/workFlow/haveList'
        //   });
        // } else if (taskType === 'waitfor') {
        //   _this.$router.push({
        //     name: 'sys/workFlow/toDoList'
        //   });
        // } else {
        //   this.$router.push({
        //     name: 'sale/return/saleReturnApplyList'
        //   });
        //   this.$bus.emit('saleReturnApply-SaveSuccess');
        // }
        this.$router.go(-1);
      }
    },
    created() {},
    mounted() {
      let selectRow = JSON.parse(this.$route.query.row);
      if (selectRow) {
        this.ruleForm.id = selectRow.id;
        if (selectRow.businessKey) {
          this.ruleForm.id = selectRow.businessKey;
        }
      }
      if (this.ruleForm.id) {
        this.backfilleditData(this.ruleForm.id);
      }

      if (selectRow) {
        this.setApprovalData(selectRow);
      }
    },
    filters: {
      setDict(v, dictName) {
        return util.setDict(v, dictName);
      },
      formatAmount(v, decimal = 2, isCovering = true) {
        return util.formatAmount(v, decimal, isCovering);
      }
    }
  };
</script>

<style lang="less" scoped>
  .top {
    overflow: hidden;

    .left-col {
      float: left;
      width: 60%;
    }

    .img {
      float: right;
    }
  }

  .visitor-details {
    p {
      color: #aab2bd;
    }

    h5 {
      font-weight: bold;
    }
  }

  .img {
    width: 40%;
    text-align: center;
    padding: 10px;
    position: relative;
    box-sizing: border-box;

    img {
      width: 100%;
    }
  }

  .panel-list {
    .panel {
      margin-top: 5px;
      background: #fff;
    }

    .divline {
      padding: 0 0 5px 0;
    }

    .icon-status {
      width: 50px;
      height: 45px;
      position: absolute;
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: center center;
      bottom: 36px;
      right: 5px;
    }

    .type-0 {
      background-image: url('../../../images/icon-queryStatus-0.png');
    }

    .type-1 {
      background-image: url('../../../images/icon-queryStatus-1.png');
    }

    .type-2 {
      background-image: url('../../../images/icon-queryStatus-2.png');
    }

    .type-3 {
      background-image: url('../../../images/icon-queryStatus-3.png');
    }

    .type-4 {
      background-image: url('../../../images/icon-queryStatus-4.png');
    }
  }

  .table {
    margin-top: 5px;

    td {
      background: #f6f6f6;
      height: auto;
      padding: 8px 5px;
      border-bottom: 5px solid #fff;
    }
  }

  .personal-table {
    td {
      text-align: left;
      word-break: break-all;
    }

    img {
      vertical-align: middle;
    }

    .idcard {
      font-size: 12px;
      padding: 0;
    }
  }

  .car-table {
    td {
      text-align: center;

      &:first-child {
        text-align: left;
      }

      &:last-child {
        text-align: right;
      }
    }
  }

  .visit-record {
    padding: 2px 0 0;

    ul {
      li {
        padding: 3px 0;
      }
    }
  }
</style>
